import React from "react";
import TicketFlowChart from "../Graph/TicketFlowChart";
import BreadCrums from "../Common/BreadCrums";

export default function Dashboard() {
  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            {
              name: "dashboard",
              path: "/customer-support/dashboard",
            },
          ]}
        />
        <div className="flex flex-col md:flex-row gap-8">
          <div className="w-full">
            <TicketFlowChart />
          </div>
          <div className="w-full flex-col flex  justify-between pt-2">
            <div className="flex items-center gap-8">
              <div className="p-4 md:p-7 w-full md:w-fit h-fit md:h-[170px] flex flex-col justify-between  rounded-xl shadow-sm">
                <span className="text-[#18120F] text-sm md:text-[19px] font-semibold">
                  Total Tickets
                </span>
                <span className="text-[#18120F] text-[35px] md:text-[76px] font-bold">
                  2000
                </span>
              </div>
              <div className="p-4 md:p-7 w-full md:w-fit h-fit md:h-[170px] flex flex-col justify-between  rounded-xl shadow-sm">
                <span className="text-[#18120F] text-sm md:text-[19px] font-semibold">
                  Tickets Today
                </span>
                <span className="text-[#18120F] text-[35px] md:text-[76px] font-bold leading-[4rem]">
                  50
                </span>
              </div>
            </div>
            <div className="flex items-center gap-8">
              <div className="p-4 md:p-7 w-full md:w-fit h-fit md:h-[170px] flex flex-col justify-between  rounded-xl shadow-sm">
                <span className="text-[#18120F] text-sm md:text-[19px] font-semibold">
                  Customer Tickets
                </span>
                <span className="text-[#18120F] text-[35px] md:text-[76px] font-bold">
                  50
                </span>
              </div>
              <div className="p-4 md:p-7 w-full md:w-fit h-fit md:h-[170px] flex flex-col justify-between  rounded-xl shadow-lg md:shadow-sm">
                <span className="text-[#18120F] text-sm md:text-[19px] font-semibold">
                  Vendor Tickets
                </span>
                <span className="text-[#18120F] text-[35px] md:text-[76px] font-bold leading-[4rem]">
                  50
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
