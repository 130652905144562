import {
    createAsyncThunk,
    createSlice
} from "@reduxjs/toolkit";
import axios from "../functions/axios";
import { CUSTOMER_FAQ, CUSTOMER_FAQ_SEARCH, CUSTOMER_LOGIN, CUSTOMER_OTP_EMAIL, CUSTOMER_OTP_VERIFY, CUSTOMER_PASSWORD_RESET, CUSTOMER_RETURN, CUSTOMER_TICKETS, CUSTOMER_TICKETS_PENDING, CUSTOMER_TICKETS_RESOLVE, CUSTOMER_TICKET_VIEW } from "./URLConstant";


const initialState = {
    data: [],
    loading: false,
    message: null,
    error: null,
    status: null,
    token: localStorage.getItem("customer_token"),
    role: localStorage.getItem("role"),
    signout:false,
};
//   *********** customer Auth Async Thuks Start Here    ***********

// customer login
export const customerLogin = createAsyncThunk("customerLogin", async (login_details, {
    rejectWithValue
}) => {
    try {
        const res = await axios.post(CUSTOMER_LOGIN, login_details);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

// customer email otp
export const customerOTPEmail = createAsyncThunk("customerOTPEmail", async (email, {
    rejectWithValue
}) => {
    try {
        const res = await axios.post(CUSTOMER_OTP_EMAIL, email);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

// customer verify otp
export const customerVerifyOTP = createAsyncThunk("customerVerifyOTP", async (otp, {
    rejectWithValue
}) => {
    try {
        const res = await axios.post(CUSTOMER_OTP_VERIFY, otp);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

// customer password reset
export const customerPasswordReset = createAsyncThunk("customerPasswordReset", async (password, {
    rejectWithValue
}) => {
    try {
        const res = await axios.post(CUSTOMER_PASSWORD_RESET, password);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

// customer tickets
export const customerSupportTickets = createAsyncThunk("customerSupportTickets", async (id, {
    rejectWithValue
}) => {
    try {
        const res = await axios.get(CUSTOMER_TICKETS);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

// customer tickets Resolved
export const customerResolvedTickets = createAsyncThunk("customerResolvedTickets", async (id, {
    rejectWithValue
}) => {
    try {
        if (id) {
            const res = await axios.get(CUSTOMER_TICKETS_RESOLVE+"?status="+id);
        return res.data;
        } else {
            const res = await axios.get(CUSTOMER_TICKETS_RESOLVE);
            return res.data;
        }
        
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});
export const customerReturnProducts = createAsyncThunk("customerReturnProducts", async (id, {
    rejectWithValue
}) => {
    try {
        const res = await axios.get(CUSTOMER_RETURN);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const customerFaq = createAsyncThunk("customerFaq", async (id, {
    rejectWithValue
}) => {
    try {
        const res = await axios.get(CUSTOMER_FAQ);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

// customer tickets Pending
export const customerPendingTickets = createAsyncThunk("customerPendingTickets", async (id, {
    rejectWithValue
}) => {
    try {
        const res = await axios.get(CUSTOMER_TICKETS_PENDING);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

// customer tickets Pending
export const getTicketCategory = createAsyncThunk("getTicketCategory", async (id, {
    rejectWithValue
}) => {
    try {
        const res = await axios.get(`/customer/ticket-category`);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

// customer ticket view
export const customerTicketView = createAsyncThunk("customerTicketView", async (id, {
    rejectWithValue
}) => {
    try {
        const res = await axios.get(CUSTOMER_TICKET_VIEW + id);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});
export const faqGetById = createAsyncThunk("faqGetById", async (id, {
    rejectWithValue
}) => {
    try {
        const res = await axios.get(CUSTOMER_FAQ+"/" + id);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});
export const faqSearch = createAsyncThunk("faqSearch", async (search, {
    rejectWithValue
}) => {
    try {
        const res = await axios.get(CUSTOMER_FAQ_SEARCH+search);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});
export const deleteFag = createAsyncThunk("deleteFag", async (id, {
    rejectWithValue
}) => {
    try {
        const res = await axios.delete(CUSTOMER_FAQ+"/" + id);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});
export const updateFAQ = createAsyncThunk("updateFAQ", async ({id,answer,title}, {
    rejectWithValue
}) => {
    try {
        const res = await axios.put(CUSTOMER_FAQ+"/" + id,{title,answer});
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});
export const addFAQ = createAsyncThunk("addFAQ", async (data, {
    rejectWithValue
}) => {
    try {
        const res = await axios.post(CUSTOMER_FAQ,data);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});
//   *********** cutomer TICKETS Async Thunks End Here    ***********



const customerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {
        clearMessage: (state) => {
            state.message = null;
            state.error = null;
            state.status = null;
        },
        resetSignout: (state) => {
            state.signout = false;
          
          },
        signoutUser: (state) => {
            localStorage.clear();
            state.token = null;
            state.role = null;
            state.signout = true;
         }
    },
    extraReducers: (builder) => {



//   *********** customer Auth Add-Cases Start Here    ***********

        // customer login
        builder
            .addCase(customerLogin.pending, (state) => {
                state.loading = true;
            })
            .addCase(customerLogin.fulfilled, (state, {
                payload
            }) => {
                state.loading = false;
                if (payload.success) {
                    state.message = payload.message;
                    state.status = payload.success;
                    state.data = payload.user;
                    state.token = payload.token
              

                        localStorage.setItem('customer_token', payload.token)
                   
                   

                } else {
                    state.message = payload.message;
                }
            })
            .addCase(customerLogin.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message
                console.log(action.payload)

                const error = action.payload?.message;

                if (error && error.includes('Unauthorized')) {
                    state.signout = true;
                  }
            });

// customer email otp
            builder
            .addCase(customerOTPEmail.pending, (state) => {
                state.loading = true;
            })
            .addCase(customerOTPEmail.fulfilled, (state, {
                payload
            }) => {
                state.loading = false;
                console.log(payload)
                if (payload.success) {
                    state.message = payload.message;
                    state.data = payload;
                    state.status = payload.success;

                } else {
                    console.log(payload)
                    state.message = payload.message;
                }
            })
            .addCase(customerOTPEmail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message
                console.log(action.payload)
            });


 // customer otp verify
            builder
            .addCase(customerVerifyOTP.pending, (state) => {
                state.loading = true;
            })
            .addCase(customerVerifyOTP.fulfilled, (state, {
                payload
            }) => {
                state.loading = false;
                console.log(payload)
                if (payload.success) {
                    state.message = payload.message;
                    state.data = payload;
                    state.status = payload.success;

                } else {
                    console.log(payload)
                    state.message = payload.message;
                }
            })
            .addCase(customerVerifyOTP.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message
                console.log(action.payload)
            });  

 // customer password verify
            builder
            .addCase(customerPasswordReset.pending, (state) => {
                state.loading = true;
            })
            .addCase(customerPasswordReset.fulfilled, (state, {
                payload
            }) => {
                state.loading = false;
                console.log(payload)
                if (payload.success) {
                    state.message = payload.message;
                    state.data = payload;
                    state.status = payload.success;

                } else {
                    console.log(payload)
                    state.message = payload.message;
                }
            })
            .addCase(customerPasswordReset.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message
                console.log(action.payload)
            });           

//   *********** customer Auth Add-Cases End Here    ***********



//   *********** customer Tickets Add-Cases Start Here    ***********


       // customer ticket
       builder
       .addCase(customerSupportTickets.pending, (state) => {
           state.loading = true;
       })
       .addCase(customerSupportTickets.fulfilled, (state, {
           payload
       }) => {
           state.loading = false;
           console.log(payload)
               state.data = payload.tickets;
       })
       .addCase(customerSupportTickets.rejected, (state, action) => {
           state.loading = false;
           const error = action.payload?.message;
           if (error && error.includes('Unauthorized')) {
               state.signout = true;
             }
       });

       // customer resolve ticktes
       builder
       .addCase(customerResolvedTickets.pending, (state) => {
           state.loading = true;
       })
       .addCase(customerResolvedTickets.fulfilled, (state, {
           payload
       }) => {
           state.loading = false;
           console.log(payload)
               state.data = payload;
       })
       .addCase(customerResolvedTickets.rejected, (state, action) => {
           state.loading = false;
           const error = action.payload?.message;
           if (error && error.includes('Unauthorized')) {
               state.signout = true;
             }
       });
       builder
       .addCase(customerReturnProducts.pending, (state) => {
           state.loading = true;
       })
       .addCase(customerReturnProducts.fulfilled, (state, {
           payload
       }) => {
           state.loading = false;
           console.log(payload.orders)
               state.data = payload.orders;
       })
       .addCase(customerReturnProducts.rejected, (state, action) => {
           state.loading = false;
           const error = action.payload?.message;
           if (error && error.includes('Unauthorized')) {
               state.signout = true;
             }
       });
       builder
       .addCase(customerFaq.pending, (state) => {
           state.loading = true;
       })
       .addCase(customerFaq.fulfilled, (state, {
           payload
       }) => {
           state.loading = false;
           console.log(payload.orders)
               state.data = payload;
       })
       .addCase(customerFaq.rejected, (state, action) => {
           state.loading = false;
           const error = action.payload?.message;
           if (error && error.includes('Unauthorized')) {
               state.signout = true;
             }
       });

       // customer pending ticktes
       builder
       .addCase(customerPendingTickets.pending, (state) => {
           state.loading = true;
       })
       .addCase(customerPendingTickets.fulfilled, (state, {
           payload
       }) => {
           state.loading = false;
               state.data = payload;
       })
       .addCase(customerPendingTickets.rejected, (state, action) => {
           state.loading = false;
           const error = action.payload?.message;
           if (error && error.includes('Unauthorized')) {
               state.signout = true;
             }
       });
       builder
       .addCase(faqSearch.pending, (state) => {
           state.loading = true;
       })
       .addCase(faqSearch.fulfilled, (state, {
           payload
       }) => {
           state.loading = false;
               state.data = payload;
       })
       .addCase(faqSearch.rejected, (state, action) => {
           state.loading = false;
           const error = action.payload?.message;
           if (error && error.includes('Unauthorized')) {
               state.signout = true;
             }
       });

       // customer tickte view
       builder
       .addCase(getTicketCategory.pending, (state) => {
           state.loading = true;
       })
       .addCase(getTicketCategory.fulfilled, (state, {
           payload
       }) => {
           state.loading = false;
               state.data = payload;
       })
       .addCase(getTicketCategory.rejected, (state, action) => {
           state.loading = false;
           const error = action.payload?.message;
           if (error && error.includes('Unauthorized')) {
               state.signout = true;
             }
       });

       // customer tickte view
       builder
       .addCase(customerTicketView.pending, (state) => {
           state.loading = true;
       })
       .addCase(customerTicketView.fulfilled, (state, {
           payload
       }) => {
           state.loading = false;
               state.data = payload;
       })
       .addCase(customerTicketView.rejected, (state, action) => {
           state.loading = false;
           const error = action.payload?.message;
           if (error && error.includes('Unauthorized')) {
               state.signout = true;
             }
       });

       builder
       .addCase(faqGetById.pending, (state) => {
           state.loading = true;
       })
       .addCase(faqGetById.fulfilled, (state, {
           payload
       }) => {
           state.loading = false;
               state.data = payload.faq;
       })
       .addCase(faqGetById.rejected, (state, action) => {
           state.loading = false;
           const error = action.payload?.message;
           if (error && error.includes('Unauthorized')) {
               state.signout = true;
             }
       });
       builder
       .addCase(updateFAQ.pending, (state) => {
           state.loading = true;
       })
       .addCase(updateFAQ.fulfilled, (state, {
           payload
       }) => {
           state.loading = false;
               state.message = payload.message;
       })
       .addCase(updateFAQ.rejected, (state, action) => {
           state.loading = false;
           const error = action.payload?.message;
           if (error && error.includes('Unauthorized')) {
               state.signout = true;
             }
       });
       builder
       .addCase(deleteFag.pending, (state) => {
           state.loading = true;
       })
       .addCase(deleteFag.fulfilled, (state, {
           payload
       }) => {
           state.loading = false;
               state.message = payload.message;
       })
       .addCase(deleteFag.rejected, (state, action) => {
           state.loading = false;
           const error = action.payload?.message;
           if (error && error.includes('Unauthorized')) {
               state.signout = true;
             }
       });
       builder
       .addCase(addFAQ.pending, (state) => {
           state.loading = true;
       })
       .addCase(addFAQ.fulfilled, (state, {
           payload
       }) => {
           state.loading = false;
               state.message = payload.message;
       })
       .addCase(addFAQ.rejected, (state, action) => {
           state.loading = false;
           const error = action.payload?.message;
           if (error && error.includes('Unauthorized')) {
               state.signout = true;
             }
       });


//   *********** customer Tickets Add-Cases End Here    ***********


    }

})


export const {
    clearMessage,
    resetSignout,
    signoutUser,
} = customerSlice.actions;
export default customerSlice.reducer;