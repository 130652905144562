import React from "react";
import CustomerSupportLayout from "../../layout/CustomerSupportLayout";
import TicketDetails from "../../Components/Tickets-Components/TicketDetails";

export default function TicketDetailsPage() {
  return (
    <CustomerSupportLayout>
      <TicketDetails />
    </CustomerSupportLayout>
  );
}
