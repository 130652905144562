import ChatPage from "../Pages/Tickets-Pages/ChatPage";
import TicketDetailsPage from "../Pages/Tickets-Pages/TicketDetailsPage";
import TicketsCategoryPage from "../Pages/Tickets-Pages/TicketsCategoryPage";
import TicketsPage from "../Pages/Tickets-Pages/TicketsPage";

const ticketsRoutes = [
  {
    path: "/customer-support/tickets",
    children: [
      {
        index: true,
        element: <TicketsPage />,
      },
      {
        path: "/customer-support/tickets/details/:id",
        element: <TicketDetailsPage />,
      },
      {
        path: "/customer-support/tickets/details/chat",
        element: <ChatPage />,
      },
    ],
  },
  {
    path: "/customer-support/tickets-category",
    element: <TicketsCategoryPage />,
  },
];

export default ticketsRoutes;
