import AddNewManagerPage from "../Pages/Role-Managment-page/AddNewManagerPage";
import ManagerListPage from "../Pages/Role-Managment-page/ManagerListPage";
import RoleManagmentAddNewPage from "../Pages/Role-Managment-page/RoleManagmentAddNewPage";
import RoleManagmentSettingPage from "../Pages/Role-Managment-page/RoleManagmentSettingPage";
import RoleManagmentViewPage from "../Pages/Role-Managment-page/RoleManagmentViewPage";


const roleManagementRoutes = [
    {
        path:"/customer-support/role-management",
        element: <RoleManagmentAddNewPage />
    },
    {
        path:"/customer-support/role-management/view",
        element: <RoleManagmentViewPage />
    },
    {
        path:"/customer-support/role-management/edit-permissions",
        element: <RoleManagmentSettingPage />
    },
    {
        path:"/customer-support/role-management/managers",
        element: <ManagerListPage/>
    },
    {
        path:"/customer-support/role-management/managers/add-new",
        element: <AddNewManagerPage/>
    },

    

    
    
    
];

export default roleManagementRoutes;