import React, { useState } from "react";
import { Link } from "react-router-dom";
import BreadCrums from "../Common/BreadCrums";

export default function RoleManagmentAddNew() {
  return (
    <>
      <div className="xl:px-4">
        <BreadCrums
          breadCrum={[
            {
              name: "Role Managment",
              path: "/customer-support/role-management",
            },
            { name: "Roles", path: "" },
          ]}
        />

        {/*  */}
        <div className="my-5 flex flex-col md:flex-row  gap-4">
          <div className="w-full md:w-[30%] border border-[#EBF0ED] rounded-lg ">
            <div className="flex items-center justify-between py-[0.7rem] px-2">
              <div className="w-full  flex items-center gap-8 px-3">
                <span className="text-xs font-semibold text-[#6B6B6B]">
                  ROLES
                </span>
              </div>
              <div className="w-full">
                <span className="float-start text-xs font-semibold text-[#6B6B6B]">
                  ACTION
                </span>
              </div>
            </div>
            <hr />
            <div className="flex items-center justify-between py-[0.6rem] px-2">
              <div className="w-full  flex items-center gap-8 px-3">
                <span className="text-xs md:text-sm font-semibold text-[#18120F]">
                  Manager
                </span>
              </div>
              <div className="w-full flex gap-4">
                <Link to="">
                  <img
                    className="w-7 h-7 md:w-9 md:h-9"
                    src="/assets/icons/edit.svg"
                    alt=""
                  />
                </Link>

                <Link to="/customer-support/role-management/managers">
                  <img
                    className="w-7 h-7 md:w-9 md:h-9"
                    src="/assets/icons/filter.svg"
                    alt=""
                  />
                </Link>
                <Link>
                  <img
                    className="w-7 h-7 md:w-9 md:h-9"
                    src="/assets/icons/delete.svg"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <hr />
          </div>
          <div className="w-full md:w-[70%] h-full  border border-[#EBF0ED] py-[0.8rem] rounded-lg ">
            <div className="flex gap-2 px-3">
              <div className="w-full flex flex-col gap-1  ">
                <label
                  htmlFor=""
                  className="text-xs font-semibold text-[#6B6B6B]"
                >
                  TITLE
                </label>
                <input
                  type="text"
                  placeholder="Enter Role Name"
                  className="border outline-none border-[#EBF0ED] text-xs font-[400] text-[#6B6B6B] p-2 md:p-3 rounded-lg bg-[#FAFAFA] "
                />
              </div>
              {/* <div className="w-full relative flex flex-col gap-1">
                <label
                  className="text-xs font-semibold text-[#6B6B6B]"
                  htmlFor="budgetCategory"
                >
                  PREVIOUS ROLE
                </label>
                <div onClick={togglerole} className=" w-full relative">
                  <input
                    className="border cursor-pointer outline-none w-full border-[#EBF0ED] text-xs font-[400] text-[#6B6B6B] px-2 rounded-lg bg-[#FAFAFA] py-2"
                    type="text"
                    value={roleValue}
                    readOnly
                    placeholder="Select Existing Role"
                  />
                  <svg
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                    width="11"
                    height="6"
                    viewBox="0 0 11 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742C1.20267 0.760742 0.962244 0.84786 0.784507 1.00307C0.695594 1.08054 0.625022 1.17271 0.576862 1.27426C0.528701 1.3758 0.503906 1.48473 0.503906 1.59474C0.503906 1.70475 0.528701 1.81367 0.576862 1.91522C0.625022 2.01677 0.695594 2.10893 0.784507 2.1864L4.80667 5.71974C4.89485 5.79784 4.99977 5.85984 5.11537 5.90214C5.23097 5.94445 5.35496 5.96623 5.48019 5.96623C5.60542 5.96623 5.72941 5.94445 5.84501 5.90214C5.96061 5.85984 6.06553 5.79784 6.15371 5.71974L10.2233 2.1864C10.3122 2.10893 10.3828 2.01677 10.4309 1.91522C10.4791 1.81367 10.5039 1.70475 10.5039 1.59474C10.5039 1.48473 10.4791 1.3758 10.4309 1.27426C10.3828 1.17271 10.3122 1.08054 10.2233 1.00307Z"
                      fill="#6B6B6B"
                    />
                  </svg>

                  {roleDropdown && (
                    <div className="absolute z-10 bg-white border border-[#EBF0ED] text-[#828282] rounded-lg mt-2 w-full">
                      {roleOptions.map((option) => (
                        <div
                          key={option}
                          className="px-2 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => handleBudgetSelect(option)}
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div> */}
            </div>
            <div className="float-end px-3 py-2">
              <button className="bg-[#C19A6B] text-xs md:text-sm font-semibold tracking-wide rounded-xl py-3 md:py-[0.95rem] px-5 md:px-9 text-white">
                Add New Role
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
