import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LuLayoutDashboard } from "react-icons/lu";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { BsShop } from "react-icons/bs";
import { useEffect, useState } from "react";
import { IoIosLogOut } from "react-icons/io";
import { BiWallet } from "react-icons/bi";
import { IoInformationCircleOutline } from "react-icons/io5";
import "./side-bar.css";
import { IoSettingsOutline } from "react-icons/io5";
import { BsPeople } from "react-icons/bs";
import { signoutUser } from "../../store/customerSlice";

export default function Sidebar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [openDropdown, setOpenDropdown] = useState(null);
  const navigate = useNavigate();

  const { signout } = useSelector((state) => state.customer);

  const handleLogout = () => {
    dispatch(signoutUser());
  };

  useEffect(() => {
    if (signout) {
      localStorage.clear();
      navigate("/");
    }
  }, [signout, navigate]);

  useEffect(() => {
    // Automatically open the dropdown of the active nav link
    if (
      location.pathname.startsWith("/customer-support/faq") ||
      location.pathname.startsWith("/customer-support/faq/add-faq")
    ) {
      setOpenDropdown("faq");
    } else if (
      location.pathname.startsWith("/customer-support/tickets") ||
      location.pathname.startsWith("/customer-support/tickets-category")
    ){
      setOpenDropdown("ticket");
    } else if (
      location.pathname === "/customer-support/role-management" ||
      location.pathname === "/customer-support/role-management/managers" ||
      location.pathname === "/customer-support/role-management/managers/add-new"
    ) {
      setOpenDropdown("role");
    } else {
      setOpenDropdown(null);
    }
  }, [location.pathname]);

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  return (
    <>
      <div className="w-full xl:w-64 h-[95vh]  bg-[#C19A6B] rounded-[0.7rem] hide-scrollbar overflow-y-auto">
        {/* shop name */}
        <div className=" px-4 py-4">
          <div className="flex gap-2 items-center mb-4">
            <div>
              <img
                className="h-14 w-14 lg:h-16 lg:w-16"
                src="/assets/images/shop-profile.svg"
                alt=""
              />
            </div>
            <div>
              <div className="flex items-center gap-2">
                <div className="text-white font-bold text-xs lg:text-base  tracking-wide">
                  Shop Name
                </div>
              </div>
            </div>
          </div>
          {/* hr */}
          <hr
            className="h-[0.1rem]"
            style={{
              borderRadius: "50%",
            }}
          />
        </div>

        <div className="flex flex-col gap-1 lg:gap-2 px-4">

          {/* dashboard  */}
          <NavLink
            to="/customer-support/dashboard"
            className={({ isActive }) => (isActive ? "activeLink" : "navLink")}
          >
            <LuLayoutDashboard className="w-4 h-4" />
            Dashboard
          </NavLink>

          {/* Tickets Category  */}
          <div className="flex relative flex-col ">
            <NavLink
              onClick={() => toggleDropdown("ticket")}
              className={`${
                location.pathname.startsWith("/customer-support/tickets") ||
                location.pathname.startsWith("/customer-support/tickets-category")
                  ? "activeLink"
                  : "navLink"
              }`}
            >
              <IoInformationCircleOutline className="w-4 h-4" />
              Tickets
              {openDropdown === "ticket" ? (
                <RiArrowDropUpLine className="!w-6 !h-6 absolute right-1" />
              ) : (
                <RiArrowDropDownLine className="!w-6 !h-6 absolute right-1" />
              )}
            </NavLink>
            {/* Store dropdown  */}
            {openDropdown === "ticket" && (
              <>
                <NavLink
                  to="/customer-support/tickets"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                  end
                >
                  Tickets
                </NavLink>
                <NavLink
                  to="/customer-support/tickets-category"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Tickets Category
                </NavLink>
              </>
            )}
          </div>

          {/* tickets */}
          {/* <NavLink
            to="/customer-support/tickets"
            className={({ isActive }) => (isActive ? "activeLink" : "navLink")}
          >
            <LuLayoutDashboard className="w-4 h-4" />
            Tickets
          </NavLink> */}

          {/* order returns */}
          <NavLink
            to="/customer-support/order-returns"
            className={({ isActive }) => (isActive ? "activeLink" : "navLink")}
          >
            <BiWallet className="w-4 h-4" />
            Order Returns
          </NavLink>

          {/* faqs  */}
          <div className="flex relative flex-col ">
            <NavLink
              onClick={() => toggleDropdown("faq")}
              className={`${
                location.pathname.startsWith("/customer-support/faq") ||
                location.pathname.startsWith("/customer-support/faq/add-faq")
                  ? "activeLink"
                  : "navLink"
              }`}
            >
              <IoInformationCircleOutline className="w-4 h-4" />
              FAQs
              {openDropdown === "faq" ? (
                <RiArrowDropUpLine className="!w-6 !h-6 absolute right-1" />
              ) : (
                <RiArrowDropDownLine className="!w-6 !h-6 absolute right-1" />
              )}
            </NavLink>
            {/* Store dropdown  */}
            {openDropdown === "faq" && (
              <>
                <NavLink
                  to="/customer-support/faq"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                  end
                >
                  FAQ List
                </NavLink>
                <NavLink
                  to="/customer-support/faq/add-faq"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Add FAQ
                </NavLink>
              </>
            )}
          </div>

          {/* Role management  */}
          <div className="flex relative  flex-col ">
            <NavLink
              onClick={() => toggleDropdown("role")}
              className={`${
                location.pathname === "/customer-support/role-management" ||
                location.pathname ===
                  "/customer-support/role-management/managers" ||
                location.pathname ===
                  "/customer-support/role-management/managers/add-new"
                  ? "activeLink"
                  : "navLink"
              }`}
            >
              <BsPeople className="w-4 h-4" />
              Role Management
              {openDropdown === "role" ? (
                <RiArrowDropUpLine className="!w-6 !h-6 absolute right-1" />
              ) : (
                <RiArrowDropDownLine className="!w-6 !h-6 absolute right-1" />
              )}
            </NavLink>
            {/* Store dropdown  */}
            {openDropdown === "role" && (
              <>
                <NavLink
                  to="/customer-support/role-management"
                  className={`${
                    location.pathname === "/customer-support/role-management"
                      ? "childActiveLink"
                      : "childLink"
                  }`}
                >
                  Roles
                </NavLink>
                <NavLink
                  to="/customer-support/role-management/managers"
                  className={`${
                    location.pathname ===
                    "/customer-support/role-management/managers"
                      ? "childActiveLink"
                      : "childLink"
                  }`}
                >
                  Managers
                </NavLink>

                <NavLink
                  to="/customer-support/role-management/managers/add-new"
                  className={`${
                    location.pathname ===
                    "/customer-support/role-management/managers/add-new"
                      ? "childActiveLink"
                      : "childLink"
                  }`}
                >
                  Add Manager
                </NavLink>
              </>
            )}
          </div>

          {/* logout  */}
          <NavLink
            to="/"
            onClick={handleLogout}
            className={({ isActive }) => (isActive ? "activeLink" : "navLink")}
          >
            <IoIosLogOut className="w-4 h-4" />
            Logout
          </NavLink>
        </div>
      </div>
    </>
  );
}
