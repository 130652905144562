import { useEffect, useState } from "react";
import BreadCrums from "../Common/BreadCrums";
import Loader from "../Common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { customerReturnProducts } from "../../store/customerSlice";
import { imagePath } from "../../functions/URLString";
export default function CustomerReturns() {
  const { loading, data } = useSelector((state) => state.customer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(customerReturnProducts());
  }, [dispatch]);
  return (
    <>
      <div className="xl:px-4 w-full flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            {
              name: "Customer Returns",
              path: "",
            },
          ]}
        />

        {/* table */}

        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto ">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}

              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[145] text-left">PRODUCT </span>
                <span className="w-[145] text-left">VENDOR</span>
                <span className="w-[145] text-left">ORDER ID</span>
                <span className="w-[145] text-left">PRICE</span>

                <span className="w-[145] text-left">REASON</span>
                <span className="w-[145] text-left">COMMENT</span>
                <span className="w-[145] text-left">ACTION</span>
              </div>

              {/* Table Body */}

              {loading ? (
                <tr>
                  <td colSpan="6">
                    <div className="flex justify-center py-4">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : Array.isArray(data) && data.length > 0 ? (
                data.map((item, index) => (
                  <div
                    key={index}
                    className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                  >
                    <div className="w-[14%] text-left  gap-3 font-[600]  flex items-center ">
                      <img
                        src={imagePath + item.product_image}
                        className="w-10 h-10  rounded-lg "
                        alt=""
                      />
                      <div className="flex flex-col ">
                        <span>{item.product_name}</span>
                        <span className="text-[#6B6B6B] text-sm font-[500]">
                          Category: {item.category_name}
                        </span>
                      </div>
                    </div>
                    <span className="w-[14%] text-left ">khan </span>
                    <span className="w-[14%] text-left">
                      #{item.order_number}
                    </span>
                    <span className="w-[14%] text-left">${item.price}</span>

                    <span className="w-[14%] text-left">{item.comments}</span>
                    <span className="w-[14%] text-left">{item.coomment}</span>

                    <div className="flex items-center gap-3 w-[14%] text-left">
                      <img src="/assets/icons/approve.svg" alt="" />
                      <img src="/assets/icons/bani.svg" alt="" />
                    </div>
                  </div>
                ))
              ) : (
                <tr>
                  <td colSpan="6">
                    <div className="text-center text-sm md:text-base font-bold tracking-wide">
                      No Customer Returns Found
                    </div>
                  </td>
                </tr>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
