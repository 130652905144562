import React from "react";
import CustomerSupportLayout from "../../layout/CustomerSupportLayout";
import RoleManagmentView from "../../Components/Role-Managment-Components/RoleManagmentView";

export default function RoleManagmentViewPage() {
  return (
    <CustomerSupportLayout>
      <RoleManagmentView />
    </CustomerSupportLayout>
  );
}
