import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import BreadCrums from "../Common/BreadCrums";
import Loader from "../Common/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  customerPendingTickets,
  customerResolvedTickets,
} from "../../store/customerSlice";

const Tickets = () => {
  const dispatch = useDispatch();

  const { loading, data } = useSelector((state) => state.customer);

  const [customerTickets, setCustomerTickets] = useState([]);
  const [activeRole, setActiveRole] = useState("Resolved");

  const handleRoleChange = (role) => {
    setActiveRole(role);
  };

  useEffect(() => {
    dispatch(customerResolvedTickets());
  }, [dispatch]);

  useEffect(() => {
    if (!loading && data && Array.isArray(data?.tickets)) {
      setCustomerTickets(data?.tickets);
    }
  }, [data, loading]);

  useEffect(() => {
    if (activeRole === "Resolved") {
      dispatch(customerResolvedTickets("resolved"));
    }
    if (activeRole === "Pending") {
      dispatch(customerResolvedTickets("pending"));
    }
  }, [activeRole, dispatch]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        {/* name */}
        <BreadCrums
          breadCrum={[
            {
              name: "Tickets",
            },
          ]}
        />

        {/* search */}
        <div className="w-full flex gap-3 items-center">
          <div className="relative w-full flex items-center">
            <img
              className="absolute left-3"
              src="/assets/icons/search.svg"
              alt=""
            />

            <input
              className="w-full py-[10px] pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-xs md:text-sm "
              type="search"
              placeholder="Search Customer Tickets ..."
            />
          </div>
        </div>

        {/* filter */}
        <div className="flex gap-6  text-xs md:text-sm">
          <NavLink
            onClick={() => handleRoleChange("Pending")}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "Pending"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            PENDING
            <span
              className={` font-semibold px-1 md:px-2 py-1   bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "Pending"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              ({!loading && data ? data.pending_count : 0})
            </span>
          </NavLink>
          <NavLink
            onClick={() => handleRoleChange("Resolved")}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "Resolved"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            RESOLVED
            <span
              className={` font-semibold px-1 md:px-2 py-1   bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "Resolved"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              {/* ({!loading && data ? data.resolved_count : 0}) */}(11)
            </span>
          </NavLink>
        </div>

        {/* table */}

        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[968px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}

              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[15%] text-left pl-3">TICKET ID</span>
                <span className="w-[15%] text-left">EMAIL</span>
                <span className="w-[15%] text-left">USER TYPE</span>
                <span className="w-[15%] text-left">STATUS</span>
                <span className="w-[20%] text-left">HEADLINE</span>
                <span className="w-[7%] text-left">ACTION</span>
              </div>

              {/* Table Body */}
            
                {loading ? (
                  <div>
                    <span colSpan="6">
                      <div className="flex justify-center py-4">
                        <Loader />
                      </div>
                    </span>
                  </div>
                ) : customerTickets && customerTickets.length > 0 ? (
                  customerTickets.map((tickt) => (
                    <div
                      key={tickt.id}
                      className=" text-sm border-b border-[#EBF0ED] text-[#18120F] h-16 rounded-b-md W-[100%] flex justify-between"
                    >
                      <span className="w-[15%]  text-left  font-[600] pl-3">
                        {tickt.ticket_id}
                      </span>
                      <span className="w-[15%] text-left font-[600]">
                        {tickt.email}
                      </span>
                      <span className="w-[15%] text-left font-[600]">
                        {tickt.user_role}
                      </span>
                      <span className="w-[15%] text-left">
                        <span
                          className={`${
                            tickt.status.toLowerCase() === "Pending"
                              ? "bg-[#FFFBF0] text-sm px-2 py-[0.189rem] border border-[#FEC600] text-[#FEC600] rounded-xl"
                              : "bg-[#F6FFF7] text-sm px-2 py-[0.189rem] border border-[#15CA32] text-[#15CA32] rounded-xl"
                          }`}
                        >
                          {capitalizeFirstLetter(tickt.status)}
                        </span>
                      </span>

                      <span className="w-[20%] text-left font-[600]">
                        {tickt.ticket_headline}
                      </span>
                      <span className="w-[7%] text-left">
                        <Link
                          to={`/customer-support/tickets/details/${tickt.id}`}
                        >
                          <img src="/assets/icons/view.svg" alt="" />
                        </Link>
                      </span>
                    </div>
                  ))
                ) : (
                  <div>
                    <span colSpan="6">
                      <div className="text-center font-bold tracking-wide">
                        No Customer Tickets Found
                      </div>
                    </span>
                  </div>
                )}
            
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tickets;
