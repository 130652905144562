import React from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Root() {
  return (
    <>
      <main>
        <div className="flex w-full flex-col">
          <ToastContainer />
          <Outlet />
        </div>
      </main>
    </>
  );
}
