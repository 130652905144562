import CustomerReturnPage from "../Pages/Order-Returns-Page/CustomerReturnPage";


const orderReturnsRoutes = [
    {
        path:"/customer-support/order-returns",
        element: <CustomerReturnPage />
    },
    
];

export default orderReturnsRoutes;