

export const CUSTOMER_LOGIN = "/customer/login"
export const CUSTOMER_OTP_EMAIL = "/customer-support-password-forgot"
export const CUSTOMER_OTP_VERIFY = "/customer-support-password-verify-otp"
export const CUSTOMER_PASSWORD_RESET = "/customer-support-password-reset"



export const CUSTOMER_TICKETS = "/ticket-list-customer-support"
export const CUSTOMER_TICKETS_PENDING = "/ticket-list-customer-support-pending"
export const CUSTOMER_TICKETS_RESOLVE = "/customer/requests"
export const CUSTOMER_TICKET_VIEW = "/customer/list-by-id/"

export const CUSTOMER_RETURN="/customer/customer-returns"
export const CUSTOMER_FAQ="/customer/faq"
export const CUSTOMER_FAQ_SEARCH="/customer/faqs-search?search="