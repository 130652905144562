import ChatPage from "../Pages/Chat-Pages/ChatPage";

const chatRoutes = [
  {
    path: "/customer-support/chat",
    element: <ChatPage />,
  },
];

export default chatRoutes;
