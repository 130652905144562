import React from "react";
import Dashboard from "../../Components/Dashboard-Compoenets/Dashboard";
import CustomerSupportLayout from "../../layout/CustomerSupportLayout";

export default function DashboardPage() {
  return (
    <CustomerSupportLayout>
      <Dashboard />
    </CustomerSupportLayout>
  );
}
