import DashboardPage from "../Pages/Dashboard-pages/DashboardPage";
import AddFAQPage from "../Pages/FAQs-Pages/AddFAQPage";
import EditFAQPage from "../Pages/FAQs-Pages/EditFAQPage";
import FAQsListPage from "../Pages/FAQs-Pages/FAQsListPage";


const faqRoutes = [
    {
        path:"/customer-support/faq",
        element: <FAQsListPage />
    },
    {
        path:"/customer-support/faq/add-faq",
        element: <AddFAQPage />
    },
    {
        path:"/customer-support/faq/edit-faq/:id",
        element: <EditFAQPage />
    },
    
    
];

export default faqRoutes;



