import React from "react";
import CustomerSupportLayout from "../../layout/CustomerSupportLayout";
import AddNewManager from "../../Components/Role-Managment-Components/AddNewManager";

export default function AddNewManagerPage() {
  return (
    <CustomerSupportLayout>
      <AddNewManager />
    </CustomerSupportLayout>
  );
}
