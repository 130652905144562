import React from "react";
import CustomerSupportLayout from "../../layout/CustomerSupportLayout";
import TicketsCategory from "../../Components/Tickets-Components/TicketsCategory";

export default function TicketsCategoryPage() {
  return (
    <CustomerSupportLayout>
      <TicketsCategory />
    </CustomerSupportLayout>
  );
}
