import React, { useEffect, useState } from "react";
import BreadCrums from "../Common/BreadCrums";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  customerFaq,
  deleteFag,
  faqSearch,
} from "../../store/customerSlice";
import Loader from "../Common/Loader";
import { toast } from "react-toastify";

export default function FAQsList() {
  const { loading, data, message } = useSelector((state) => state.customer);

  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const deleteFAQ = (id) => {
    dispatch(deleteFag(id));
    dispatch(customerFaq());
  };
  useEffect(() => {
    dispatch(customerFaq());
  }, []);
  useEffect(() => {
    dispatch(faqSearch(search));
  }, [search]);
  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
    }
  }, [message]);
  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        <div className="flex items-center justify-between">
          <BreadCrums
            breadCrum={[
              {
                name: "FAQs",
                path: "/customer-support/faq",
              },
              {
                name: "FAQ List",
                path: "/customer-support/faq",
              },
            ]}
          />
          <Link
            to="/customer-support/faq/add-faq"
            className=" bg-[#C19A6B] px-5 md:px-9 py-[7px] md:py-[10px] text-xs md:text-sm rounded-lg text-white "
          >
            Add FAQ
          </Link>
        </div>
        {/* search */}
        <div className="relative flex items-center w-full ">
          <img
            src="/assets/icons/search.svg"
            alt=""
            className="absolute left-3 text-[#C19A6B]"
          />

          {/* search  */}
          <input
            className="w-full py-2 md:py-3 px-7  rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-xs md:text-sm"
            type="search"
            placeholder="Search…"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        {/* table */}
        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto ">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* heading */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[30%]">FAQ</span>
                <span className="w-[60%]">ANSWER</span>
                <span className="w-[10%]">ACTION</span>
              </div>
              {/* rows */}
              {loading ? (
                <tr>
                  <td colSpan="6">
                    <div className="flex justify-center py-4">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : Array.isArray(data?.faq) && data?.faq.length > 0 ? (
                data?.faq?.map((item, index) => (
                  <div className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b">
                    <span className="w-[30%]">{item.title}</span>
                    <span className="w-[60%]">{item.answer}</span>
                    <div className="w-[10%] flex items-center gap-2">
                      <Link to={"/customer-support/faq/edit-faq/" + item.id}>
                        <img src="/assets/icons/edit.svg" alt="" />
                      </Link>
                      <img
                        src="/assets/icons/delete.svg"
                        alt=""
                        onClick={(e) => deleteFAQ(item.id)}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <tr>
                  <td colSpan="6">
                    <div className="text-center font-bold tracking-wide">
                      No Customer Returns Found
                    </div>
                  </td>
                </tr>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
