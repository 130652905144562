import React from "react";
import CustomerSupportLayout from "../../layout/CustomerSupportLayout";
import Chat from "../../Components/Tickets-Components/Chat";

export default function ChatPage() {
  return (
    <CustomerSupportLayout>
      <Chat />
    </CustomerSupportLayout>
  );
}
