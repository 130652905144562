import DashboardPage from "../Pages/Dashboard-pages/DashboardPage";

const dashboardRoutes = [
  {
    path: "/customer-support/dashboard",
    element: <DashboardPage />,
  },
];

export default dashboardRoutes;
