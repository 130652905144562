import React, { useEffect, useState } from "react";
import BreadCrums from "../Common/BreadCrums";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, faqGetById, updateFAQ } from "../../store/customerSlice";
import { toast } from "react-toastify";

export default function EditFAQ() {
  const { id } = useParams();
  const { data, loading, message } = useSelector((state) => state.customer);
  const [updatedData, setUpdatedData] = useState({ title: null, answer: null });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(faqGetById(id));
  }, []);
  const updateFaq = () => {
    dispatch(
      updateFAQ({ title: updatedData.title, answer: updatedData.answer, id })
    );
  };
  useEffect(() => {
    setUpdatedData({ ...updatedData, title: data.title, answer: data.answer });
  }, [data]);
  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
    }
  }, [message]);
  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4 ">
        <div className="flex items-center justify-between">
          <BreadCrums
            breadCrum={[
              {
                name: "FAQs",
                path: "/customer-support/faq",
              },
              {
                name: "Edit FAQ",
                path: "",
              },
            ]}
          />
          <Link
            onClick={(e) => updateFaq(id)}
            className=" bg-[#C19A6B] px-5 md:px-9 py-[7px] md:py-[10px] text-xs md:text-sm rounded-lg text-white "
          >
            Update
          </Link>
        </div>

        <h2 className="text-base md:text-lg font-semibold text-[#18120F]">
          FAQ
        </h2>
        <div className="w-full border flex flex-col gap-3 border-[#EBF0ED] rounded-xl p-3">
          <div className="flex flex-col gap-1">
            <label
              className="text-xs font-semibold ml-2 text-[#6B6B6B]"
              htmlFor=""
            >
              FAQ
            </label>
            <input
              className="w-full outline-none rounded-xl p-2 md:p-3 text-xs md:text-sm border border-[#EBF0ED] bg-[#FAFAFA]"
              placeholder="Vitae tristique ridiculus massa nullam congue sed in ultrices vitae. Auctor fermentum sit ac leo. At tellus sagittis donec eu ut vel nunc."
              type="text"
              name=""
              id=""
              value={updatedData && updatedData.title}
              onChange={(e) =>
                setUpdatedData({ ...updatedData, title: e.target.value })
              }
            />
          </div>
          <div className="flex flex-col gap-1">
            <label
              className="text-xs font-semibold ml-2 text-[#6B6B6B]"
              htmlFor=""
            >
              ANSWER
            </label>
            <textarea
              className="w-full outline-none resize-none rounded-xl p-2 md:p-3 text-xs md:text-sm border border-[#EBF0ED] bg-[#FAFAFA]"
              placeholder="Condimentum tincidunt elit porta nunc consequat amet. Scelerisque odio pellentesque tellus purus viverra dui ac ornare porttitor. Dui vel id pellentesque aliquam at maecenas cursus quis. Et felis cursus metus elit sed. Aliquet fames adipiscing et lectus gravida aliquet massa hac facilisis. Facilisi risus lacus integer in quam libero adipiscing et mi. Eget quis ac ultricies consectetur. Enim nisl enim posuere lectus. Tincidunt habitant urna nulla id in. Ante magna adipiscing nunc etiam nibh ac enim. Condimentum tincidunt elit porta nunc consequat amet. Scelerisque odio pellentesque tellus purus viverra dui ac ornare porttitor. Dui vel id pellentesque aliquam at maecenas cursus quis. Et felis cursus metus elit sed. Aliquet fames adipiscing et lectus gravida aliquet massa hac facilisis. Facilisi risus lacus integer in quam libero adipiscing et mi. Eget quis ac ultricies consectetur. Enim nisl enim posuere lectus. Tincidunt habitant urna nulla id in. Ante magna adipiscing nunc etiam nibh ac enim. "
              type="text"
              name=""
              rows={7}
              value={updatedData && updatedData.answer}
              onChange={(e) =>
                setUpdatedData({ ...updatedData, answer: e.target.value })
              }
              id=""
            />
          </div>
        </div>
      </div>
    </>
  );
}
