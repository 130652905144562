import Chat from "../../Components/Chat/Chat";
import CustomerSupportLayout from "../../layout/CustomerSupportLayout";

export default function ChatPage() {
  return (
    <CustomerSupportLayout>
      <Chat />
    </CustomerSupportLayout>
  );
}
