import React, { useEffect, useState } from "react";
import BreadCrums from "../Common/BreadCrums";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addFAQ } from "../../store/customerSlice";

export default function AddFAQ() {
  const [faq, setFaq] = useState({ title: "", answer: "" });
  const { message, error } = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  useEffect(() => {
    if (message) {
      toast.success(message);
    }
  }, [message]);

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4 ">
        <div className="flex items-center justify-between">
          <BreadCrums
            breadCrum={[
              {
                name: "FAQs",
                path: "/customer-support/faq",
              },
              {
                name: "Add FAQ",
                path: "/customer-support/faq/add-faq",
              },
            ]}
          />
          <Link
            // to="/customer-support/faq/add-faq"
            onClick={(e) => dispatch(addFAQ(faq))}
            className=" bg-[#C19A6B] px-5 md:px-9 py-[7px] md:py-[10px] text-xs md:text-sm rounded-lg text-white "
          >
            Save
          </Link>
        </div>

        <h2 className="text-base md:text-lg font-semibold text-[#18120F]">
          FAQ
        </h2>
        <div className="w-full border flex flex-col gap-3 border-[#EBF0ED] rounded-xl p-3">
          <div className="flex flex-col gap-1">
            <label
              className="text-xs font-semibold ml-2 text-[#6B6B6B]"
              htmlFor=""
            >
              FAQ
            </label>
            <input
              className="w-full outline-none rounded-xl p-2 md:p-3 text-xs md:text-sm border border-[#EBF0ED] bg-[#FAFAFA]"
              placeholder="Title.."
              type="text"
              name=""
              id=""
              onChange={(e) => {
                setFaq({ ...faq, title: e.target.value });
              }}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label
              className="text-xs font-semibold ml-2 text-[#6B6B6B]"
              htmlFor=""
            >
              ANSWER
            </label>
            <textarea
              className="w-full outline-none rounded-xl resize-none p-2 md:p-3 text-xs md:text-sm border border-[#EBF0ED] bg-[#FAFAFA]"
              placeholder="answer.."
              type="text"
              name=""
              id=""
              onChange={(e) => {
                setFaq({ ...faq, answer: e.target.value });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
