import React from "react";
import CustomerSupportLayout from "../../layout/CustomerSupportLayout";
import FAQsList from "../../Components/FAQs-Components/FAQsList";

export default function FAQsListPage() {
  return (
    <CustomerSupportLayout>
      <FAQsList />
    </CustomerSupportLayout>
  );
}
