import React, { useEffect, useState } from "react";
import BreadCrums from "../Common/BreadCrums";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { customerTicketView } from "../../store/customerSlice";
import { imagePath } from "../../functions/URLString";
import Loader from "../Common/Loader";

export default function TicketDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.customer);

  const [TicketDetails, setTicketDetails] = useState(null);

  useEffect(() => {
    dispatch(customerTicketView(id));
  }, [dispatch]);

  useEffect(() => {
    if (!loading && data && data.ticket) {
      setTicketDetails(data.ticket);
    }
  }, [loading, data]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-5">
        <div className="flex items-center justify-between">
          <BreadCrums
            breadCrum={[
              {
                name: "Tickets",
                path: "/customer-support/tickets",
              },
              {
                name: TicketDetails && TicketDetails.ticket_id,
                path: `/customer-support/tickets/details/${id}`,
              },
            ]}
          />
          <Link
            to="/customer-support/tickets/details/chat"
            className="px-5 md:px-9 py-[6px] md:py-[10px] bg-[#C19A6B] rounded-lg text-white"
          >
            Chat
          </Link>
        </div>

        {/* cards */}
        <div className="w-full border border-[#EBF0ED] flex flex-col md:flex-row justify-between gap-4 md:gap-10 rounded-lg p-5">
          <div className="w-full flex flex-col gap-3">
            <div className="text-xs font-semibold flex items-center justify-between ">
              <span className="text-[#6B6B6B]">FULL NAME:</span>
              <span>{TicketDetails && TicketDetails.name}</span>
            </div>
            <span className="border-b border-[#EBF0ED] mt-1"></span>
            <div className="text-xs font-semibold flex items-center justify-between ">
              <span className="text-[#6B6B6B]">EMAIL:</span>
              <span>{TicketDetails && TicketDetails.email}</span>
            </div>
            <span className="border-b border-[#EBF0ED] mt-1"></span>

            <div className="text-xs font-semibold flex items-center justify-between ">
              <span className="text-[#6B6B6B]">DATE CREATED:</span>
              <span>15 5 2022</span>
            </div>
            <span className="border-b border-[#EBF0ED] mt-1"></span>
          </div>
          <div className="w-full flex flex-col gap-3">
            <div className="text-xs font-semibold flex items-center justify-between ">
              <span className="text-[#6B6B6B]">STATUS:</span>
              <span>{TicketDetails && TicketDetails.status}</span>
            </div>
            <span className="border-b border-[#EBF0ED] mt-1"></span>

            <div className="text-xs font-semibold flex items-center justify-between ">
              <span className="text-[#6B6B6B]">CATEGORY:</span>
              <span>Technical Support</span>
            </div>
            <span className="border-b border-[#EBF0ED] mt-1"></span>

            <div className="text-xs font-semibold flex items-center justify-between ">
              <span className="text-[#6B6B6B]">HEADING:</span>
              <span>{TicketDetails && TicketDetails.ticket_headline}</span>
            </div>
            <span className="border-b border-[#EBF0ED] mt-1"></span>
          </div>{" "}
          <div className="w-full flex flex-col gap-3">
            <div className="text-xs font-semibold flex items-center justify-between ">
              <span className="text-[#6B6B6B]">USER TYPE:</span>
              <span>{TicketDetails && TicketDetails.user_role}</span>
            </div>
          </div>
        </div>
        {/* summary */}
        <div>
          <h2 className="text-lg font-semibold">Ticket Summary</h2>
          <div className="border border-[#EBF0ED] p-3 bg-[#FAFAFA] rounded-lg text-xs font-[400]">
            <span>{TicketDetails && TicketDetails.ticket_summary}</span>
          </div>
        </div>
        {/* attachment */}
        <div className="border border-[#EBF0ED] p-4  rounded-lg">
          <h2 className="text-lg font-semibold">Attachments</h2>
          <div className="flex gap-2 items-center justify-between">
            <div className="w-full flex justify-start">
              <div className="  border border-dashed border-[#E0E0E0] w-[100px] h-[75px] rounded-md">
                <img
                  src={
                    TicketDetails && imagePath + TicketDetails.upload_attachment
                  }
                  alt="ticket_attachment"
                />
              </div>
            </div>
            <div className="w-full flex justify-start">
              <div className=" border border-dashed border-[#E0E0E0] w-[100px] h-[75px] rounded-md"></div>
            </div>
            <div className="w-full flex justify-start">
              <div className=" border border-dashed border-[#E0E0E0] w-[100px] h-[75px] rounded-md"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
