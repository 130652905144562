import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  clearMessage,
  customerLogin,
  resetSignout,
} from "../../store/customerSlice";

import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

export default function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, loading, message, error, status, role, token } = useSelector(
    (state) => state.customer
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleLogin = () => {
    if (email === "" || password === "") {
      toast.error("Please Fill All the Fields");
    } else {
      dispatch(customerLogin({ email, password }));
    }
  };

  useEffect(() => {
    if (!loading) {
      if (status) {
        toast.success("Logged Inn Successfully");
        if ( token) {
          navigate("/customer-support/dashboard");
        }
      }
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());

    // set signout false
    dispatch(resetSignout());
  }, [message, error, status]);

  useEffect(() => {
    localStorage.clear();
  }, []);

  // for password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="h-[100vh] flex justify-center items-center px-4 md:px-0 bg-[#FFFAF4]">
      <div
        className="w-full md:w-fit p-4 md:p-14 bg-white flex flex-col items-center rounded-3xl"
        style={{ boxShadow: "0px 5px 30px 0px #00000008" }}
      >
        <img className="mx-auto" src="/assets/images/login-logo.svg" alt="" />
        <div className="font-bold tracking-wide text-xl md:text-3xl mt-5 mb-2 text-center">
          Login to Account
        </div>
        <div className="text-xs md:text-sm text-center font-bold tracking-wide">
          Login to your Wizalot account!
        </div>
        <div className="w-full md:w-[30rem] mt-6">
          <label className="form-control ">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Enter Your Email Address
              </span>
            </div>
            <input
              type="email"
              placeholder="mike.tyson@mail.com"
              className="p-3 md:p-4 border border-[#EBF0ED] text-sm rounded-lg bg-[#FAFAFA] w-full  focus:outline-none focus:border-[#EBF0ED]"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label className="form-control mt-3">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Enter Your Password
              </span>
            </div>
            <span className="flex items-center relative">
              <input
                type={passwordVisible ? "text" : "password"}
                placeholder="********"
                className="p-3 md:p-4 border border-[#EBF0ED]  text-sm rounded-lg bg-[#FAFAFA] w-full  focus:outline-none focus:border-[#EBF0ED]"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordVisible ? (
                <IoEyeOutline
                  className="absolute right-4 cursor-pointer"
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <IoEyeOffOutline
                  className="absolute right-4 cursor-pointer"
                  onClick={togglePasswordVisibility}
                />
              )}
            </span>
          </label>
          <div className="flex justify-end mt-1">
            <Link
              to="/forgot-password"
              className="text-[#C19A6B] text-sm md:text-[15px] font-semibold "
            >
              Forgot Password?
            </Link>
          </div>
          <button
            disabled={loading}
            onClick={handleLogin}
            className="bg-[#C19A6B] w-full rounded-lg py-3 md:p-4 md:py-4 text-sm md:text-base mt-[1.5rem] text-white font-bold tracking-wider"
          >
            {!loading ? "Login" : "Loading"}
          </button>
        </div>
        {/* <div className="flex items-center justify-center mt-2 gap-2">
          <span className="text-sm">Want to register your mall?</span>
          <Link className="font-semibold text-base text-[#C19A6B]">
            Sign Up
          </Link>
        </div> */}
      </div>
    </div>
  );
}
