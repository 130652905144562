import React from "react";
import CustomerSupportLayout from "../../layout/CustomerSupportLayout";
import RoleManagmentSetting from "../../Components/Role-Managment-Components/RoleManagmentSetting";

export default function RoleManagmentSettingPage() {
  return (
    <CustomerSupportLayout>
      <RoleManagmentSetting />
    </CustomerSupportLayout>
  );
}
