import React from "react";
import { useNavigate } from "react-router-dom";

function BreadCrums({ breadCrum }) {
  const navigate = useNavigate();

  return (
    <div className="flex items-center gap-2">
      <img src="/assets/icons/bars.svg" alt="" />
      {breadCrum.map((item, index) => (
        <React.Fragment key={index}>
          <span
            className={`cursor-pointer text-xs md:text-sm ${
              index === breadCrum.length - 1
                ? "font-bold"
                : "text-[#828282] font-normal"
            }`}
            onClick={() => navigate(item.path)}
          >
            {item.name}
          </span>
          {index !== breadCrum.length - 1 && (
            <img src="/assets/icons/forwardi.svg" alt="" />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

export default BreadCrums;
