import React from "react";
import Root from "../Pages/Root";
import authRoutes from "./authRoutes";
import dashboardRoutes from "./dashboardRoutes";
import ticketsRoutes from "./ticketsRoutes";
import orderReturnsRoutes from "./orderReturnsRoutes";
import faqRoutes from "./faqRoutes";
import roleManagementRoutes from "./roleManagementRoutes";
import ProtectedRoute from "../ProtectedRoute";
import chatRoutes from "./chatRoutes";

const mainRoutes = [
  {
    path: "/",
    element: <Root />,
    children: [
      ...authRoutes,
      {
        path: "/customer-support",
        element: <ProtectedRoute />,
        children: [
          ...dashboardRoutes,
          ...ticketsRoutes,
          ...orderReturnsRoutes,
          ...faqRoutes,
          ...roleManagementRoutes,
          ...chatRoutes,
        ],
      },
    ],
  },
];
export default mainRoutes;
