import React from "react";
import CustomerSupportLayout from "../../layout/CustomerSupportLayout";
import CustomerReturns from "../../Components/Order-Returns-Component/CustomerReturns";

export default function CustomerReturnPage() {
  return (
    <CustomerSupportLayout>
      <CustomerReturns />
    </CustomerSupportLayout>
  );
}
