import React from "react";
import CustomerSupportLayout from "../../layout/CustomerSupportLayout";
import ManagerList from "../../Components/Role-Managment-Components/ManagerList";

export default function ManagerListPage() {
  return (
    <CustomerSupportLayout>
      <ManagerList />
    </CustomerSupportLayout>
  );
}
