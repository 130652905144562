import React from "react";
import CustomerSupportLayout from "../../layout/CustomerSupportLayout";
import EditFAQ from "../../Components/FAQs-Components/EditFAQ";

export default function EditFAQPage() {
  return (
    <CustomerSupportLayout>
      <EditFAQ />
    </CustomerSupportLayout>
  );
}
