import React from "react";
import CustomerSupportLayout from "../../layout/CustomerSupportLayout";
import AddFAQ from "../../Components/FAQs-Components/AddFAQ";

export default function AddFAQPage() {
  return (
    <CustomerSupportLayout>
      <AddFAQ />
    </CustomerSupportLayout>
  );
}
