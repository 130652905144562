import React, { useEffect, useState } from "react";
import BreadCrums from "../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { getTicketCategory } from "../../store/customerSlice";

const TicketsCategory = () => {
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.customer);
  const [image, setImage] = useState();

  useEffect(()=>{
    dispatch(getTicketCategory());
  },[])

  useEffect(()=>{
    console.log(data);
  },[data])

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
  };

  return (
    <form
    id="uploadForm"
    enctype="multipart/form-data"
    method="post"
    className="xl:px-4 flex flex-col gap-4 w-full"
    >
      <div className="flex  flex-col md:flex-row gap-3 justify-between items-center w-full ">
        {/* name */}
        <BreadCrums
          breadCrum={[
            {
              name: "Tickets",
            },
          ]}
        />

        {/* submit btn  */}
        <div className="flex justify-end w-full md:w-fit">
            <button
              disabled={loading ? true : false}
              onClick={handleSubmit}
              className="py-2 md:py-[15px] px-5 md:px-8 bg-[#C19A6B]  rounded-lg text-white  font-semibold text-xs md:text-sm tracking-widest"
            >
              {loading?"Publishing":"Publish"}
            </button>
        </div>

      </div>

      {/* create form  */}
      <div className="flex flex-col gap-2">
        <p className="font-semibold text-lg">Create Category</p>

        <div className="flex flex-col md:flex-row  border border-[#EBF0ED] p-4 gap-10 md:gap-4 lg:gap-10 rounded-lg">
          <div className="w-full flex flex-col gap-4">
            {/* name  */}
            <div className="flex flex-col gap-1 w-full">
              <label
                className="text-[#6B6B6B]  font-semibold text-xs uppercase"
                htmlFor="product name "
              >
                Category Name
              </label>
              <input
                className="bg-[#FAFAFA] rounded-lg w-full py-2 px-3   outline-[#EBF0ED] border border-[#EBF0ED] placeholder:text-xs placeholder:text-[#6B6B6B]"
                type="text"
                name="name"
                id=""
                placeholder="name..."
                // value={categoryName}
                // onChange={(e) => setCategoryName(e.target.value)}
              />
            </div>
          </div>

          <div className="w-full flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#6B6B6B]  font-semibold text-xs">ICON IMAGE</h1>

              <div className=" grid grid-cols-2 gap-4 md:gap-2 lg:gap-4 w-full md:w-fit">
                  <div
                    key=""
                    className="flex flex-col items-center w-full md:w-28"
                  >
                    <div
                      className={`relative w-full h-[90px] border rounded-lg flex justify-center items-center border-dashed border-[#E0E0E0] `}
                    >
                      {image ? (
                        <div className="w-full h-full relative">
                          <img
                            src=""
                            alt={`Uploaded-`}
                            className="w-full h-full object-cover rounded-lg"
                          />
                          {/* Delete Button */}
                          <button
                            className="absolute top-1 right-1 bg-white border border-[#EBF0ED] rounded-full w-4 h-4 flex justify-center items-center"
                            // onClick={() => handleImageDelete(index)}
                          >
                            <svg
                              width="7"
                              height="7"
                              viewBox="0 0 7 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.9748 1.00017L1.00002 5.975M1 1L5.9748 5.9748"
                                stroke="black"
                                stroke-width="1.07"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      ) : (
                        <label className="cursor-pointer flex w-full h-full items-center justify-center">
                          <input
                            type="file"
                            accept="image/*"
                            className="hidden"
                            // onChange={(e) => handleImageUpload(e, index)}
                          />
                          <span className="flex bg-[#D9D9D9] rounded-full w-4 h-4 items-center justify-center text-white">
                            +
                          </span>
                        </label>
                      )}
                    </div>
                  </div>
              </div>
            </div>
          </div>

        </div>

      </div>

        {/* Self Service Tools */}
        <div className="md:px-12">
          <h2 className="text-xl font-semibold text-[#18120F] text-center py-3">
            Ticket Categories
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8">
            {data?.categories?.length > 0? data.categories.map((a)=> (
                <>
                <div className="border hover:bg-[#dab78c] cursor-pointer flex flex-col gap-2 items-center justify-center border-[#D9D9D9] px-5 py-6 rounded-2xl">
                <img
                    src={a.icon}
                    className="w-7 h-7 lg:w-10 lg:h-10"
                    alt=""
                />
                <span className="text-[10px] md:text-sm text-center font-[400] text-[#18120F]">
                    {a.label}
                </span>
                </div>{" "}
                </>
            )):(<></>)}
          </div>
        </div>

    </form>
  );
};

export default TicketsCategory;
