import React from "react";
import CustomerSupportLayout from "../../layout/CustomerSupportLayout";
import RoleManagmentAddNew from "../../Components/Role-Managment-Components/RoleManagmentAddNew";

export default function RoleManagmentAddNewPage() {
  return (
    <CustomerSupportLayout>
      <RoleManagmentAddNew />
    </CustomerSupportLayout>
  );
}
